<template>
	<main
		class="container-white"
		:class="{'mobile-container': mobileCheck()}"
	>
		<section class="meal-page container-base">
			<div class="meal-page__grid">
				<div class="meal-page__left-column">
					<RestaurantInfo
						class="meal-page__restaurant-info"
						:name="curRest.name || ''"
						:ogrn="curRest.ogrn || ''"
						:inn="curRest.inn || ''"
						@change="showRestaurantsForm"
					/>
					<div
						class="meal-page__back-link"
						v-if="!currentBasket?.id"
					>
						<router-link to="/menu"><img src="~@/assets/img/arrowBack.svg"/> Назад в основное меню
						</router-link>
					</div>
					<div class="meal-page__swipe-wrapper">
						<v-touch
							ref="tapper"
							v-on:swipeup="swipeUp"
							v-on:swipedown="swipeDown"
						>
							<div
								class="meal-page__swipe-content"
								:class="{'meal-page__swipe-content--priority': showMobileRest,}"
							>
								<div
									class="meal-page__swipe-content-wr"
									:class="{ 'meal-page__swipe-content-wr--fixed': showMobileRest }"
									ref="swipeRest"
								>
									<div
										class="meal-page__swipe-top"
										@click="swipeDown"
									>
										<span class="meal-page__swipe-feature"></span>
									</div>
									<div>
										<Restaurants
											:restaurants="restaurantsList"
											@selectRestaurant="changeRest"
											:showMobileRest="showMobileRest"
										/>
									</div>
								</div>
							</div>
						</v-touch>
					</div>
					<div class="meal-page__title">
						<h2>{{ mealTitle }}</h2>
						<div class="meal-page__title-time">
							<span>{{ deliveryTime?.[currentMeal] }}</span>
						</div>
					</div>
					<div class="meal-page__date-and-time">
						<SwitchLine
							:canSwitch="!currentBasket?.id"
							@itemChanged="switchDay"
							:selectedItem="currentDayStr"
							class="meal-page__date"
							:items="[
									{name: 'Меню на сегодня', value: 'today'},
									{name: 'Меню на завтра', value: 'tomorrow'},
								]"
						/>

						<SwitchLine
							v-if="false"
							:canSwitch="!currentBasket?.id"
							v-model="time"
							class="meal-page__time"
							:items="[
									{name: 'Завтрак 8:30-10:30', value: 'breakfast', disabled: true},
									{name: 'Обед 11:30-16:30', value: 'lunch'},
									{name: 'Ужин 17:30-21:30', value: 'dinner'},
								]"
						/>
					</div>
					<BlockPreloader
						class="meal-page__preloader"
						v-if="menuLoading"
					/>
					<FoodTypeSelector
						v-if="!restClosed && !menuLoading"
						class="meal-page__food-type-selector hide-scroll"
						:constructorPage="constructorPage"
						:whithCount="true"
						:items="$store.state.mealByGroups"
						:sticky="foodTypeSelectorSticky"
						:style="{top: `${foodTypeSelectorShift}px`}"
						ref="foodtypeselector"
						@select="selectCategory"
					/>
					<div
						style="height: 134px"
						v-if="foodTypeSelectorSticky && !restClosed"
					/>
					<RestClosed
						class="meal-page__rest-closed"
						v-if="!menuLoading && restClosed"
						:tomorrow="$store.state.banner.showTomorrowOrderButton"
						:isMealPage="currentMeal === 'breakfast' ? 'завтрак' : 'ужин'"
					/>
					<SectionTitle
						v-if="!restClosed && !menuLoading && businessMeals.foodItems.length && currentMeal !== 'breakfast'"
						class="page-menu__section-title"
					>
						{{ getSectionTitle }}
						<span
							v-if="currentBasket?.collective"
							class="page-menu__collective-title"
						>
							Коллективная <br> корзина
						</span>
					</SectionTitle>
					<BusinessMealList
						v-if="!restClosed && !menuLoading && businessMeals.foodItems.length && currentMeal !== 'breakfast'"
						class="page-menu__business-lunch-list"
						:items="businessMeals.foodItems || []"
						:badge="getBadge"
						:description="getDescription"
						@setCurrentBusinessMealType="setCurrentBusinessMealType"
					/>
					<div v-if="!restClosed && !menuLoading">
						<div
							v-for="category in meals"
							:key="category.type"
						>
							<SectionTitle
								class="page-menu__section-title"
								:ref="category.type"
							>
								{{ category.title }}
								<span
									v-if="currentBasket?.collective"
									class="page-menu__collective-title"
								>
									Коллективная <br> корзина
								</span>
							</SectionTitle>
							<MealList
								:items="category.foodItems || []"
								:categoryBackground="getCategoryColor(category.type)"
								class="meal-page__meal-list-category"
								@selected="selectedItem = $event; selectedItemPopup = true;"
							/>
						</div>
					</div>
				</div>
				<div
					class="meal-page__right-column"
					v-if="!menuLoading && !mobileCheck()"
					ref="minicart"
				>
					<MiniCart :style="{top: `${minicartShift}px`}"/>
				</div>
			</div>
		</section>
		<BottomNavigation/>
		<Popup :visible.sync="showRestSelection">
			<Restaurants
				:restaurants="$store.state.restaraunts"
				@selectRestaurant="changeRest"
			/>
		</Popup>
		<Popup
			:visible.sync="selectedItemPopup"
			class="meal-page__meal-popup"
			:hideCloseBtn="mealPopupComponent != 'MealPopup'"
		>
			<Component
				:is="mealPopupComponent"
				v-if="selectedItem"
				@close="selectedItemPopup = false"
				:item="selectedItem"
				:id="parseInt(selectedItem.id)"
				:imgs="[selectedItem.preview_picture.thumb, selectedItem.detail_picture.thumb]"
				:type="selectedItem.type.name"
				:nutrition="{
							calories: selectedItem.kkal,
							proteins: selectedItem.belki,
							fats: selectedItem.jiri,
							carbs: selectedItem.uglevodi
						}"
				:rating="5"
				:name="selectedItem.name"
				:badges="[]"
				:composition="selectedItem.sostav"
				:discount="parseInt(selectedItem.discount)"
				:price="selectedItem.price"
				:inAppPrice="null"
				:weight="parseInt(selectedItem.weight)"
				:bonus="parseInt(selectedItem.balls)"
				:rest="curRest"
			/>
		</Popup>
		<Popup :visible.sync="isMeal">
			<PlaceOrder
				@closePopup="isMeal = false"
				:isMealPage="mealTitle"
			/>
		</Popup>
	</main>
</template>

<script>
import RestaurantInfo from "../components/block/Menu/RestaurantInfo.vue";
import Restaurants from "../components/popup/Restaurants.vue";
import SwitchLine from "../components/ui/SwitchLine.vue";
import MiniCart from "../components/cart/MiniCart.vue";
import BottomNavigation from "@/components/nav/BottomNavigation";
import MealList from "../components/block/Menu/MealList.vue";
import RestClosed from "../components/block/Menu/RestClosed.vue";
import BlockPreloader from "../components/ui/BlockPreloader.vue";
import MealPopup from "@/components/block/Menu/MealPopup.vue";
import MealMobilePopup from "@/components/block/Menu/MealMobilePopup.vue";
import PlaceOrder from "@/components/popup/PlaceOrder.vue";
import search from "@/mixins/search.js";
import {mobileCheck} from "@/helpers";
import jivoSite from "../mixins/jivoSite";

import {mapActions, mapGetters} from "vuex";

export default {
	components:
		{
			RestaurantInfo,
			Restaurants,
			SwitchLine,
			MiniCart,
			BottomNavigation,
			MealList,
			RestClosed,
			BlockPreloader,
			MealPopup,
			MealMobilePopup,
			PlaceOrder,
			FoodTypeSelector: () => import("../components/block/Menu/FoodTypeSelector.vue"),
			BusinessMealList: () => import("../components/block/Menu/BusinessMealList.vue"),
			SectionTitle: () => import("../components/block/SectionTitle.vue"),
		},
	name: "Meal",
	mixins: [jivoSite, search],
	data()
	{
		return {
			currentMeal: "",
			mealTypes: {
				breakfast: "Завтраки",
				dinner: "Ужины",
			},

			isMeal: false,
			showRestSelection: false,
			swipeRestHeight: 0,
			showMobileRest: false,

			constructorPage: false,
			foodTypeSelectorSticky: false,
			foodTypeSelectorShift: 0,

			date: "today",
			time: "lunch",

			minicartShift: 0,
			selectedItem: null,
			selectedItemPopup: false,
		};
	},
	computed:
		{
			...mapGetters({
				curRest: "order/currentRestaurant",
				deliveryTime: "restaurant/getRestDeliveryTime",
				currentDayStr: 'currentDayStr',
				restaurantsList: 'getRestaurants',
				currentRestarauntId: "getRestarauntId",
				currentBasket: 'getCurrentBasket',
				menuProductsCount: "getMenuProductsCount",
				menuLoading: "getMenuLoading",
				timeRange: "getTimeRange"
			}),
			getSectionTitle()
			{
				return `Комбо-${this.currentMeal === 'breakfast' ? 'завтраки' : 'ужины'}`;
			},
			getBadge()
			{
				return `Собери свой ${this.currentMeal === 'breakfast' ? 'завтрак' : 'ужин'}`;
			},
			getDescription()
			{
				return `Добавляйте любые блюда в ваш конструктор ${this.currentMeal === 'breakfast' ? 'завтрака' : 'ужина'}`;
			},
			mealTitle()
			{
				return this.mealTypes[this.currentMeal] || ""
			},
			restClosed()
			{
				return !this.menuProductsCount
			},
			meals()
			{
				return this.$store.state.mealByGroups.filter(meal => meal.type !== "lunches") || {foodItems: []};
			},
			mealPopupComponent()
			{
				return window.innerWidth < 991 ? "MealMobilePopup" : "MealPopup";
			},
			businessMeals()
			{
				return this.$store.state.mealByGroups.filter(meal => meal.type === "lunches")[0] || {foodItems: []};
			},
		},
	methods:
		{
			...mapActions({
				getRestDeliveryTime: "restaurant/getRestDeliveryTime",
				getBasketTypes: "getBasketTypes",
				getMenu: "getMenu",
			}),
			mobileCheck,
			// Записывает текущий тип конструктора
			setCurrentBusinessMealType()
			{
				this.$store.commit('choseBusinessMealType', this.currentMeal)
			},
			getCategoryColor(category)
			{

				let res = this.$store.state.menuCategories.find(c => c.type == category);

				if (res.color)
					return "#E7FAFB";
				else
					return "#fff";
			},
			showRestaurantsForm(width)
			{
				if (width <= 767)
				{
					this.swipeUp();
				} else
				{
					this.showRestSelection = true;
				}
			},
			async changeRest(rest)
			{
				this.showRestSelection = false;

				this.$store.commit("setRestarauntId", rest.id);
				await this.getMenu();
				await this.$store.dispatch("getCart");
				this.swipeDown();
			},
			swipeDown()
			{
				if (window.innerWidth <= 767)
				{
					this.showMobileRest = false;
					this.$refs.swipeRest.style.bottom = "-" + this.swipeRestHeight + "px";
					document.querySelector("body").classList.remove("page-no-scroll");
				}
			},
			swipeUp()
			{
				if (window.innerWidth <= 767)
				{
					this.showMobileRest = true;
					this.$refs.swipeRest.style.bottom = "0px";
					document.querySelector("body").classList.add("page-no-scroll");
				}
			},
			async switchDay(day)
			{
				await this.$store.dispatch("setDate", day);

				if (day === "tomorrow") this.$router.push('/menu');
			},
			updateMinicartPos()
			{
				if (!this.$refs.mainblock || !this.$refs.minicart) return;

				// Если страница находится не в начале, смещение корзины вниз
				this.minicartShift = window.scrollY > 0 ? 20 : 0;

				// Если хэдер виден, смещение корзины под хэдер
				this.minicartShift += this.$store.state.ui.headerVisibility && window.scrollY > 0 ? 115 : 0;

				// Смещение по размеру списка категорий
				this.minicartShift += this.foodTypeSelectorSticky ? 72 : 0;
			},
			// Проверка видимости меню выбора категории
			updateFoodTypeSelectorVisibility()
			{
				const isHeaderVisible = this.$store.state.ui.headerVisibility;
				const isDesktop = window.innerWidth >= 990;
				const scrollY = window.scrollY;

				this.foodTypeSelectorShift = (isHeaderVisible && isDesktop) ? 120 : 0;

				const stickyThreshold = isDesktop ? 380 : 360;
				this.foodTypeSelectorSticky = scrollY >= stickyThreshold;
			},
			// Скролл до выбранной категории
			selectCategory(type)
			{
				const el = this.$refs[type].$el || this.$refs[type][0].$el;

				let top = el.getBoundingClientRect().top + window.pageYOffset - 100;

				if (window.scrollY > el.getBoundingClientRect().top + window.pageYOffset && window.innerWidth > 990)
				{
					top -= 120;
				}

				window.scrollTo({top, behavior: "smooth"});
			},
			addEventListeners()
			{
				window.addEventListener('scroll', this.updateFoodTypeSelectorVisibility);
				window.addEventListener('resize', this.updateFoodTypeSelectorVisibility);
				window.addEventListener("scroll", this.updateMinicartPos, {passive: true});
			}
		},
	watch:
		{

			async currentDayStr()
			{
				await this.$store.dispatch("getBannerData");
			},

			async restaurantsList()
			{
				if (this.restaurantsList.length === 0)
					this.$router.push("/");
				else if (this.restaurantsList.length === 1)
				{
					await this.$store.commit("setRestarauntId", this.restaurantsList[0].id);
					await this.$store.dispatch("setDate", this.currentDayStr);
				} else if (!!this.currentRestarauntId)
				{
					this.$store.commit("setRestarauntId", this.currentRestarauntId);
					await this.$store.dispatch("setDate", this.currentDayStr);
				} else
				{
					this.$store.commit("setRestarauntId", false);
					this.showRestSelection = true;
				}
			},
			async currentRestarauntId()
			{
				this.showJivo(this.currentRestarauntId);

				await this.getRestDeliveryTime();
			},
			async timeRange()
			{
				if (this.timeRange === "lunch") return;

				await this.getMenu(this.timeRange);
			}
		},
	async mounted()
	{
		await this.getRestDeliveryTime();

		if (mobileCheck()) await this.getBasketTypes();

		this.addEventListeners();

		this.showJivo(this.currentRestarauntId);

		const currentPath = this.$route.path.replace('/', '');

		this.currentMeal = currentPath || "";
		if (this.curRest)
		{
			this.$store.commit('setTimeRange', currentPath);
		} else
		{
			this.showRestaurantsForm(window.innerWidth);
		}
	},
	beforeDestroy()
	{
		this.hideJivo();

		window.removeEventListener('scroll', this.updateFoodTypeSelectorVisibility);
		window.removeEventListener("scroll", this.updateMinicartPos);
		window.removeEventListener('resize', this.updateFoodTypeSelectorVisibility);
	}
};
</script>

<style lang="scss">
.meal-page
{
	min-height: 88vh;
	padding-top: 8px;

	&__food-type-selector
	{
		margin-bottom: 23px;
		padding-top: 12px;
		padding-bottom: 23px;

		margin-left: -44px;
		margin-right: -44px;
		padding-left: 44px;
		padding-right: 44px;
		max-width: 954px;
		transition: top 0.2s ease-in-out;
	}

	&__food-type-selector.sticky
	{
		padding: 0;
		margin: 0;
		max-width: unset;
	}
}

.page-menu__section-title
{
	gap: 14px;
	margin-bottom: 32px;
}

.page-menu__collective-title
{
	display: none;
	letter-spacing: 0.02em;
	border-left: 1px solid $greyDelimiter;
	padding-left: 16px;
	font-weight: 700;
	font-size: 10px;
	line-height: 140%;
	text-transform: uppercase;

	@media (max-width: 999px)
	{
		display: block;
	}
}

.meal-page__grid
{
	display: grid;
	grid-template-columns: minmax(400px, 1fr) 354px;
	gap: 0 56px;
	width: 100%;
	position: relative;
}

.meal-page__restaurant-info
{margin-bottom: 24px;}

.meal-page__back-link
{
	margin-bottom: 24px;

	a
	{
		display: flex;
		align-items: center;
		gap: 4px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #3D4248;
	}
}

.meal-page__swipe-wrapper
{display: none;}

.meal-page__swipe-content
{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	z-index: 5;

	&--priority
	{z-index: 12;}
}

.meal-page__swipe-content-wr
{
	max-width: 354px;
	margin-left: 10px;
	background: #FFFFFF;
}

.meal-page__swipe-top
{
	position: absolute;
	height: 28px;
	width: calc(100% - 40px);
	margin: 0 20px 0 20px;
	background: #FFFFFF;
	text-align: center;
}

.meal-page__swipe-feature
{
	display: inline-block;
	width: 44px;
	height: 4px;
	background: #E3E3E3;
	border-radius: 16px;
}

.meal-page__title
{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;

	h2
	{
		font-weight: 700;
		font-size: 40px;
		line-height: 48px;
		letter-spacing: -0.5px;
		color: #3D4248;
	}

	.meal-page__title-time
	{
		padding: 8px 12px;
		background: #F8F8F8;
		border-radius: 40px;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #3D4248;
	}
}

.meal-page__date-and-time
{
	display: flex;
	justify-content: space-between;
	margin-bottom: 32px;
}

.meal-page__date
{width: 311px;}

.meal-page__time
{width: 435px;}

.meal-page .meal-page__preloader
{
	height: 440px;
	border-radius: 24px;
}

.meal-page__meal-popup
{
	.popup-block
	{
		width: 960px;
		border-radius: 24px;
		padding: 40px;
	}
}

@media (max-width: 1439px)
{
	.meal-page__grid
	{gap: 0 48px;}
	.meal-page__time
	{display: none;}
}

@media (max-width: 1024px)
{
	.meal-page__grid
	{
		grid-template-columns: minmax(400px, 1fr) 340px;
		gap: 0 32px;
	}
}

@media (max-width: 1000px)
{
	.meal-page__grid
	{display: block;}
	.meal-page__right-column
	{display: none;}
}

@media (max-width: 990px)
{
	.meal-page__meal-popup
	{
		.popup-block
		{
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			max-width: unset;
			max-height: unset;
			height: 100%;
			border-radius: 0;
			padding: 0;
		}
	}
}

@media (max-width: 767px)
{
	.meal-page__swipe-wrapper
	{display: flex;}
	.meal-page__swipe-content-wr
	{
		margin-left: 0;
		position: fixed;
		z-index: 5;
		height: 0;
		width: 100%;
		max-width: unset;
		transition: .2s ease-in-out;

		&--fixed
		{
			height: 100%;
			padding-right: 0;
			padding-left: 0;
			padding-top: 0;
		}
	}
}

@media (max-width: 567px)
{
	.meal-page__food-type-selector
	{
		margin-left: -32px;
		margin-right: -32px;
		padding-left: 32px;
		padding-right: 32px;
	}
	.meal-page__grid
	{padding-bottom: 25px;}
	.meal-page__date
	{width: 100%;}
	.meal-page__meal-list-category
	{margin-bottom: 0;}
	.meal-page__title
	{
		h2
		{
			font-size: 32px;
			line-height: 40px;
		}

		.meal-page__title-time
		{padding: 5px;}
	}

}
</style>
